import React, { forwardRef, Fragment, useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from '@reduxjs/toolkit'
import { userContext } from '../../context/userContext'
import {
  apiSlice,
  selectLastMessageId,
  selectLastSeenId,
  selectMessageAuthors,
  selectOwner,
  selectOwnerUsers,
  selectParticipants,
  selectRoles,
  selectThreadById,
  sortedMessages,
  useAddMessageMutation,
  useFetchMessagesQuery,
  useUpdateLastReadMutation
} from '../api/apiSlice'
import { format, isSameDay } from 'date-fns'
import { findIndex, first, last } from 'lodash'
import InfiniteScrollLoader from '../../common/InfiniteScrollLoader'
import { viewContext } from '../../context/viewContext'
import { useDisplayParticipants } from '../../common/useDisplayParticipants'
import { ChoiceChip } from '../../common/ChoiceChip'

function determineMessageType(participants) {
  if (!participants || participants.length === 0) return null

  if (participants.some(p => p.role !== null)) return 'group'

  if (participants.length === 2) return 'individual'

  return 'group'
}

function showNewLabel(index, lastSeenIndex) {
  if (index === 0 && lastSeenIndex === null) {
    return true
  }

  if (index > 0 && index - 1 === lastSeenIndex) {
    return true
  }

  return false
}

const useShowScrollArrow = (ref, drawerOpen, initialScrollHeightRef, setShowArrow, isSuccess) => {
  useEffect(() => {
    if (!drawerOpen || !isSuccess || !ref.current) return
    const element = ref.current

    if (initialScrollHeightRef.current === null) {
      initialScrollHeightRef.current = element.scrollHeight
    }

    const handleScroll = () => {
      const isScrolledToBottom = element.scrollTop + element.clientHeight >= element.scrollHeight - 1

      if (!isScrolledToBottom && element.scrollHeight > initialScrollHeightRef.current) {
        setShowArrow(true)
      } else if (isScrolledToBottom) {
        setShowArrow(false)
      }
    }

    element.addEventListener('scroll', handleScroll)
    return () => element.removeEventListener('scroll', handleScroll)
  }, [ref, drawerOpen, isSuccess])
}

const useInfiniteScroll = (ref, isFetching, page, pagination, isError, setPage, setPrevScrollHeight, thread_id) => {
  const loadMore = useCallback(() => {
    if (page[thread_id] === pagination?.total_pages) return

    if (!isFetching && !isError) {
      setPrevScrollHeight(ref.current.scrollHeight)
      setPage(prev => ({
        ...prev,
        [thread_id]: (prev?.[thread_id] ?? 1) + 1
      }))
    }
  }, [page, pagination?.total_pages, isFetching, isError, thread_id])

  useEffect(() => {
    const element = ref.current
    if (!element || !thread_id) return

    const handleScroll = () => {
      if (element.scrollTop === 0 && !isFetching) {
        loadMore()
      }
    }

    element.addEventListener('scroll', handleScroll)
    return () => element.removeEventListener('scroll', handleScroll)
  }, [isFetching, loadMore, thread_id])
}

const useScrollPosition = (
  ref,
  isFetching,
  prevScrollHeight,
  setPrevScrollHeight,
  data,
  newMessageSent,
  setNewMessageSent
) => {
  useEffect(() => {
    const element = ref.current

    if (!element) return

    if (newMessageSent) {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth',
      })
      setNewMessageSent(false)
    } else if (data && !isFetching) {
      const newScrollHeight = element.scrollHeight
      element.scrollTop = newScrollHeight - prevScrollHeight

    }
  }, [isFetching, prevScrollHeight, data, newMessageSent, ref])
}

const useDrawerScroll = (ref, isSuccess, drawerOpen) => {
  useEffect(() => {
    if (ref.current && isSuccess && drawerOpen) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
  }, [drawerOpen, isSuccess, ref])
}


const useDailyMessages = (messagesList) => {
  return useMemo(() => {
    const dailyMessages = []
    let currentDate = new Date(0)

    for (let message of messagesList) {
      const messageDate = new Date(Date.parse(message.posted_at))

      if (!isSameDay(currentDate, messageDate)) {
        currentDate = messageDate
        dailyMessages.push({ date: currentDate, messages: [] })
      }

      last(dailyMessages).messages.push(message)
    }

    return dailyMessages
  }, [messagesList])

}

const useFirstUnseenMessage = (thread_id, page) => {
  const { messagesPerPage } = useContext(viewContext)
  const { messages, last_seen_id } = useFetchMessagesQuery({ thread_id, page: page[thread_id] || 1, per_page: messagesPerPage }, {
    selectFromResult: (result) => ({
      messages: sortedMessages(result, thread_id),
      last_seen_id: selectLastSeenId(result, thread_id)
    }),
    skip: !thread_id
  })


  return useMemo(() => {
    if (!last_seen_id) return first(messages)

    const lastSeen = messages.findIndex(message => message.id == last_seen_id)

    return messages[lastSeen + 1]
  }, [messages, last_seen_id])
}

const ViewOnlyBanner = () => {
  return (
    <div className='view-only-banner dome-p2 dome-d-flex dome-align-center dome-justify-center dome-gap3'>
      <span className='dome-color-dark-green'><i className='fal fa-eye'></i> view only |</span><span className='dome-color-dark-grey'>This thread is view only.</span>
    </div>
  )
}

const useCalculateWrap = (containerRef, chipRefs, seeMoreRef, participants, showMore) => {
  const [isOverflow, setIsOverflow] = useState(false)
  const [visibleCount, setVisibleCount] = useState(participants?.length)
  const displayedParticipants = useMemo(() => {
    return isOverflow && !showMore
      ? participants?.slice(0, visibleCount)
      : participants
  }, [isOverflow, participants, visibleCount, showMore])

  useLayoutEffect(() => {
    const container = containerRef?.current
    const chips = chipRefs?.current
    const btn = seeMoreRef?.current

    if (!container || !btn || !chips?.length || !participants?.length) return
    const containerWidth = container.getBoundingClientRect().width
    const btnWidth = btn.getBoundingClientRect().width

    let totalWidth = 0
    let count = 0

    for (let chip of chips) {
      const chipWidth = chip.getBoundingClientRect().width
      totalWidth += chipWidth

      if (count > 0) {
        const colGap = parseFloat(getComputedStyle(container)?.columnGap) || 0
        totalWidth += colGap
      }

      if (totalWidth + btnWidth > containerWidth) {
        setIsOverflow(true)
        setVisibleCount(count)
        break
      }

      count++
    }

  }, [participants])

  return { isOverflow, visibleCount, displayedParticipants }
}

const ParticipantLabels = ({ owner, participants, customLabel}) => {
  const [showMore, setShowMore] = useState(false)
  const seeMoreRef = useRef(null)
  const containerRef = useRef(null)
  const chipRefs = useRef([])
  const { isOverflow, visibleCount, displayedParticipants } = useCalculateWrap(containerRef, chipRefs, seeMoreRef, participants, showMore)

  const handleSeeMore = () => setShowMore(!showMore)

  const countNotShown = participants?.length - displayedParticipants?.length

  const classes = ['dome-d-flex', 'dome-align-center', 'dome-gap6']
  const seeMoreBtnClasses = ['see-more-btn', 'dome-btn', 'dome-btn-link', 'dome-color-med-grey']
  if (showMore) {
    classes.push('dome-flex-wrap')
    seeMoreBtnClasses.push('dome-btn-link-chevron-left')
  }

  return (
    <div>
      <div ref={containerRef} className={classes.join(' ')}>
        {chipRefs.current = []}
        {displayedParticipants?.map((participant, index) => {
          const userIds = owner?.messageable_group_users[participant.role]
          const userList = userIds?.map((id) => ({
            id,
            full_name: owner?.users[id].name,
            role: participant.role,
          }))

          return (
            <ChoiceChip
              ref={el => chipRefs.current[index] = el}
              key={index}
              choiceChipData={{
                label: participant.role ? owner?.roles[participant.role]?.display_select : participant.label,
                value: participant.label,
                user: participant.isUser,
              }}
              userList={userList}
              customLabel={customLabel(participant, userList)}
            />
          )
        })}
        <button
          ref={seeMoreRef}
          onClick={handleSeeMore}
          style={{visibility: isOverflow ? 'visible' : 'hidden'}}
          className={seeMoreBtnClasses.join(' ')}
        >
          {showMore ? 'see less' : `+ ${countNotShown} more`}
        </button>
      </div>
    </div>
  )
}

const ChatHeader = ({ onClose, activeThreadId, page, setPage }) => {
  const { messagesPerPage, owner_id } = useContext(viewContext)
  const { data, participantList, owner, canPost, lastMessageId, isFetching, isLoading } = useFetchMessagesQuery({ thread_id: activeThreadId, per_page: messagesPerPage, page: page[activeThreadId] || 1 }, {
    selectFromResult: (result) => ({
      ...result,
      participantList: selectParticipants(result, activeThreadId),
      owner: selectOwner(result, owner_id),
      canPost: selectThreadById(result, activeThreadId)?.can_post || false,
      lastMessageId: selectLastMessageId(result, activeThreadId)
    }),
    skip: !activeThreadId
  })
  const participants = useDisplayParticipants(participantList, owner?.users, owner)
  const cantPost = !isFetching && data && !canPost && activeThreadId
  const title = useMemo(() => {
    const messageType = determineMessageType(participantList)
    return messageType ? `${messageType} message` : ' '
  }, [participantList, determineMessageType])

  const handleClose = useCallback(() => {
    onClose({thread_id: activeThreadId, message_id: lastMessageId, per_page: messagesPerPage})
  }, [onClose, activeThreadId, lastMessageId, messagesPerPage])

  const customLabel = useCallback((participant, userList) => (
    <div className='dome-d-flex dome-align-center dome-gap6'>
      <i className={`fal ${participant?.isUser ? 'fa-user' : 'fa-user-group'}`} />
      <div>{participant?.label} {participant?.role && userList?.length > 0 && <>({userList?.length})</>}</div>
    </div>
  ), [])

  return (
    <div className="chat-header" style={{ marginBottom: cantPost ? '37px' : '0' }}>
      {cantPost && <ViewOnlyBanner />}
      <div className="dome-d-flex dome-flex-column dome-gap12 dome-align-start">
        <button onClick={handleClose} className="dome-btn dome-btn-link dome-btn-link-back">
          back
        </button>
        <div className='dome-d-flex dome-flex-column dome-gap6 dome-full-width'>
          <div className="dome-p1 dome-color-lite-grey">{title}</div>
          {!isLoading && activeThreadId ? (
            <ParticipantLabels owner={owner} participants={participants} customLabel={customLabel} />
          ) : (
            '...'
          )}
        </div>
      </div>
    </div>
  )
}

const ChatMessageInput = forwardRef(({ drawerOpen, onClick, activeThreadId, page }, ref) => {
  const { unique_owner_id,  messagesPerPage } = useContext(viewContext)
  const { canPost } = useFetchMessagesQuery({ thread_id: activeThreadId, per_page: messagesPerPage, page: page[activeThreadId] || 1 }, {
    selectFromResult: (result) => ({canPost: selectThreadById(result, activeThreadId)?.can_post || false}),
    skip: !activeThreadId
  })
  const [value, setValue] = useState('')
  const hasInput = value && value.length > 0 && value.trim().length
  const disabled = !canPost || !hasInput
  const buttonClasses = ['send-button','dome-btn', 'dome-btn-base-fit-content']
  buttonClasses.push(disabled ? 'dome-btn-disabled' : 'dome-btn-go-green')

  const handleChange = (e) => setValue(e.target.value)
  const handleClick = () => {
    if (disabled) return

    onClick(value)
    setValue('')
  }
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick()
    }
  }

  useEffect(() => {
    if (!drawerOpen) {
      setValue('')
    }
  }, [drawerOpen])

  return (
    <div className='chat-message-input-container'>
      <div className="dome-d-flex dome-align-center dome-gap6">
        <input
          ref={ref}
          type="text"
          value={value}
          placeholder='type your reply here...'
          className='chat-input dome-rounded-border dome-full-width'
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          disabled={!canPost}
        />
        <button className={buttonClasses.join(' ')} onClick={handleClick}><i className='fal fa-send' /></button>
      </div>
    </div>
  )
})
ChatMessageInput.displayName = 'ChatMessageInput'

const ChatBubble = ({ message }) => {
  const date = new Date(message.posted_at)
  const formattedTimestamp = format(date, 'hh:mm aaa, MM/dd/yy')

  return (
    <div className='chat-bubble dome-d-flex dome-flex-column dome-justify-between dome-gap12 dome-rounded-border dome-light-shadow dome-bg-white'>
      <div className="message-content">
        {message.body}
      </div>
      <div className='dome-p3 dome-color-med-grey'>
        {formattedTimestamp}
      </div>
    </div>
  )
}

const MessageRow = ({messageUser, hideConsecutiveName, isCurrentUserMessage, roleDisplay, children}) => {

  return (
    <div className='message-row'>
      {(!isCurrentUserMessage && !hideConsecutiveName) && <div className='name-container dome-p3 dome-text-w500 dome-color-darkest-grey'>{messageUser.name} <span className='role-display dome-color-med-grey'>{roleDisplay}</span></div>}
      <div className={`chat-bubble-container dome-d-flex ${isCurrentUserMessage ? 'dome-justify-end' : ''}`}>
        {children}
      </div>
    </div>
  )
}

const NewMessageDivider = () => {
  return (
    <div className='new-message-divider'>
      <span className='dome-color-aqua dome-p3 dome-text-w500'>new messages</span>
    </div>
  )
}

const MessageDay = ({ date }) => {
  return (
    <div>friday i think</div>
  )
}

const MessageBlock = ({ message, hideConsecutiveName, messageUser, isCurrentUserMessage, roleDisplay, page, shouldRefetch }) => {
  const firstUnseen = useFirstUnseenMessage(message?.message_thread_id, page)
  const isFirstUnseen = message?.id == firstUnseen?.id

  return (
    <>
      { isFirstUnseen && !shouldRefetch ? <NewMessageDivider /> : null }
      <MessageRow hideConsecutiveName={hideConsecutiveName} messageUser={messageUser} isCurrentUserMessage={isCurrentUserMessage} roleDisplay={roleDisplay} >
        <ChatBubble message={message} />
      </MessageRow>
    </>
  )
}

const DayOfMessages = ({ date, messages, activeThreadId, page, shouldRefetch }) => {
  const { owner_id, unique_owner_id, messagesPerPage } = useContext(viewContext)
  const { currentUser} = useContext(userContext)
  const { messageAuthors, roles, owner, users, isLoading } = useFetchMessagesQuery({ thread_id: activeThreadId, page: page[activeThreadId] || 1, per_page: messagesPerPage }, {
    selectFromResult: (result) => ({
      ...result,
      messageAuthors: selectMessageAuthors(result, activeThreadId) || {},
      roles: selectRoles(result, owner_id) || {},
      owner: selectOwner(result, unique_owner_id),
      users: selectOwnerUsers(result, unique_owner_id)
    }),
    skip: !activeThreadId
  })

  if (isLoading || !users || !messageAuthors || !roles) return

  return (
    <div className="day-block">
      { date && <MessageDay date={date} /> }
      { messages.map((message, index) => {
        const hideConsecutiveName = index !== 0 && messages[index - 1]?.posted_by === message?.posted_by
        const messageUser = users && users[message?.posted_by]
        const isCurrentUserMessage = currentUser == messageUser?.id
        const messageAuthorType = messageAuthors && messageAuthors[message.posted_by]
        const roleDisplay = roles[messageAuthorType]?.display_singular

        return (
          <MessageBlock
            key={message.id}
            message={message}
            roleDisplay={roleDisplay}
            hideConsecutiveName={hideConsecutiveName}
            messageUser={messageUser}
            isCurrentUserMessage={isCurrentUserMessage}
            page={page}
            shouldRefetch={shouldRefetch}
          />
        )
      }) }
    </div>
  )
}

const ChatMessagesList = forwardRef(({ activeThreadId, drawerOpen, setShowArrow, page, setPage, shouldRefetch, newMessageSent, setNewMessageSent, prevScrollHeight, setPrevScrollHeight }, ref) => {
  const initialScrollHeightRef = useRef(null)

  const { currentUser } = useContext(userContext)
  const { messagesPerPage } = useContext(viewContext)
  const { currentData, messagesList, pagination, isSuccess, isFetching, isLoading, isError } = useFetchMessagesQuery({ thread_id: activeThreadId, page: page[activeThreadId] || 1, per_page: messagesPerPage }, {
    selectFromResult: (result) => ({
      ...result,
      messagesList: sortedMessages(result, activeThreadId),
      pagination: result.data?.pagination
    }),
    skip: !activeThreadId
  })

  useInfiniteScroll(ref, isFetching, page, pagination, isError, setPage, setPrevScrollHeight, activeThreadId)
  useScrollPosition(ref, isFetching, prevScrollHeight, setPrevScrollHeight, currentData, newMessageSent, setNewMessageSent)
  useDrawerScroll(ref, isSuccess, drawerOpen)
  useShowScrollArrow(ref, drawerOpen, initialScrollHeightRef, setShowArrow, isSuccess)

  const dailyMessages = useDailyMessages(messagesList)


  return (
    <>
      {isFetching && <InfiniteScrollLoader style={{position: 'absolute', top: '12rem', zIndex: '2', left: '50%'}}/>}
      <div ref={ref} className='dome-scrollable dome-relative'>
        <div className='chat-message-list-container dome-d-flex dome-flex-column dome-justify-end dome-bg-fill'>
          { pagination?.total_pages === page[activeThreadId] && !isFetching && <div className='dome-color-med-grey dome-p3' style={{textAlign: 'center', padding: '0 12px'}}>This is the beginning.</div> }
          {dailyMessages?.map((day, index) => {
            // date = null, disabling message days for now
            return <DayOfMessages
              key={day.date}
              date={null}
              messages={day.messages}
              activeThreadId={activeThreadId}
              page={page}
              shouldRefetch={shouldRefetch}
            />
          })}
        </div>
      </div>
    </>
  )
})
ChatMessagesList.displayName = 'ChatMessagesList'


const ChatScrollArrow = forwardRef(({ showArrow }, ref) => {
  const classes = 'scroll-to-top-arrow dome-bg-white dome-absolute dome-d-flex dome-align-center dome-justify-center dome-light-shadow'
  const scrollToBottom = useCallback(() => {
    const element = ref.current
    if (element && element.scrollTop >= 0) {
      element.scrollTo({
        top: element.scrollHeight,
        behavior: 'smooth'
      })
    }
  }, [])
  return (
    <div
      onClick={scrollToBottom}
      className={classes}
      style={{opacity: showArrow ? 1 : 0, pointerEvents: showArrow ? 'auto' : 'none'}}
    >
      <i style={{fontSize: '22px'}} className='far fa-arrow-down dome-color-aqua' />
    </div>
  )
})

const Backdrop = ({ onClose, activeThreadId, page }) => {
  const { messagesPerPage } = useContext(viewContext)
  const { lastMessageId } = useFetchMessagesQuery({ thread_id: activeThreadId, per_page: messagesPerPage, page: page[activeThreadId] || 1 }, {
    selectFromResult: (result) => ({
      lastMessageId: selectLastMessageId(result, activeThreadId)
    }),
    skip: !activeThreadId
  })

  const handleClose = useCallback(() => {
    onClose({thread_id: activeThreadId, message_id: lastMessageId, per_page: messagesPerPage})
  }, [onClose, activeThreadId, lastMessageId, messagesPerPage])

  return (
    <div onClick={handleClose} className="drawer-backdrop"></div>
  )
}

const ChatMain = forwardRef(({ onClose, activeThreadId, drawerOpen, setShowArrow, page, setPage, shouldRefetch, newMessageSent, setNewMessageSent, prevScrollHeight, setPrevScrollHeight }, ref) => {
  return (
    <>
      <ChatHeader onClose={onClose} activeThreadId={activeThreadId} page={page} setPage={setPage} />
      <ChatMessagesList
        ref={ref}
        drawerOpen={drawerOpen}
        activeThreadId={activeThreadId}
        setShowArrow={setShowArrow}
        page={page}
        setPage={setPage}
        shouldRefetch={shouldRefetch}
        newMessageSent={newMessageSent}
        setNewMessageSent={setNewMessageSent}
        prevScrollHeight={prevScrollHeight}
        setPrevScrollHeight={setPrevScrollHeight}
      />
    </>
  )
})
ChatMain.displayName = 'ChatMain'

const MessengerDrawer = ({ activeThreadId, drawerOpen, onClose, shouldRefetch, setShouldRefetch, threadPage, page, setPage }) => {
  const dispatch = useDispatch()
  const [showArrow, setShowArrow] = useState(false)
  const [newMessageSent, setNewMessageSent] = useState(false)
  const [prevScrollHeight, setPrevScrollHeight] = useState(0)
  const [updateLastRead] = useUpdateLastReadMutation()
  const [addMessage] = useAddMessageMutation()
  const scrollableContainerRef = useRef(null)
  const inputRef = useRef(null)
  const drawerRef = useRef(null)
  const { unique_owner_id, messagesPerPage, threadsPerPage, focusId } = useContext(viewContext)

  const { currentData, lastSeenId, lastMessageId, isLoading, isFetching } = useFetchMessagesQuery({ thread_id: activeThreadId, per_page: messagesPerPage, page: page[activeThreadId] || 1 }, {
    selectFromResult: (result) => ({
      ...result,
      lastSeenId: selectLastSeenId(result, activeThreadId),
      lastMessageId: selectLastMessageId(result, activeThreadId)
    }),
    skip: !activeThreadId
  })

  const handleClose = useCallback((params) => {
    if (lastSeenId !== lastMessageId && !shouldRefetch) {
      updateLastRead(params)
      dispatch(apiSlice.endpoints.getInitialLoad.initiate(
        { unique_owner_ids: [unique_owner_id], per_page: threadsPerPage, page: threadPage },
        { subscribe: false, forceRefetch: true }
      ))
    }

    onClose()
  }, [onClose, dispatch, unique_owner_id, threadsPerPage, threadPage, lastSeenId, lastMessageId])

  const handleSend = useCallback((value) => {
    const newMessage = {
      thread_id: activeThreadId,
      message: value,
      unique_owner_id,
      page: page[activeThreadId],
      per_page: messagesPerPage
    }

    addMessage(newMessage)
    setShouldRefetch(true)
    setNewMessageSent(true)
    setPrevScrollHeight(-scrollableContainerRef?.current.scrollHeight)

    // when sending a new message, make sure to get latest messages
    dispatch(apiSlice.endpoints.fetchMessages.initiate(
      { thread_id: activeThreadId, per_page: messagesPerPage, page: 1 },
      { subscribe: false, forceRefetch: true }
    ))
  }, [activeThreadId, unique_owner_id, page, messagesPerPage, dispatch])

  const handleTransitionEnd = () => inputRef.current.focus({ preventScroll: true })

  useEffect(() => {
    if (activeThreadId && !(activeThreadId in (page || {}))) {
      setPage(prev => ({
        ...prev,
        [activeThreadId]: 1  // Initialize to 1 if it’s a new activeThreadId
      }))
    }
  }, [activeThreadId])

  useEffect(() => {
    if (drawerRef?.current && focusId == activeThreadId && isLoading) {
      drawerRef?.current.scrollIntoView({block: 'center', behavior: 'smooth'})
    }
  }, [focusId, activeThreadId, isLoading])

  return (
    <div ref={drawerRef} className={`messenger-drawer-container ${drawerOpen ? 'open' : ''}`} onTransitionEnd={handleTransitionEnd}>
      <div className="dome-relative" style={{height: '100%'}}>
        <Backdrop onClose={handleClose} activeThreadId={activeThreadId} page={page} />
        <div className="messenger-drawer dome-bg-white dome-full-width dome-relative">
          <ChatMain
            ref={scrollableContainerRef}
            drawerOpen={drawerOpen}
            activeThreadId={activeThreadId}
            setShowArrow={setShowArrow}
            page={page}
            setPage={setPage}
            onClose={handleClose}
            shouldRefetch={shouldRefetch}
            newMessageSent={newMessageSent}
            setNewMessageSent={setNewMessageSent}
            prevScrollHeight={prevScrollHeight}
            setPrevScrollHeight={setPrevScrollHeight}
          />
          <ChatMessageInput ref={inputRef} drawerOpen={drawerOpen} onClick={handleSend} activeThreadId={activeThreadId} page={page} />
          <ChatScrollArrow ref={scrollableContainerRef} showArrow={showArrow} />
        </div>
      </div>
    </div>
  )
}



export default MessengerDrawer
